import React, { useState, useEffect } from "react";
import { Button, Space } from "antd";
import { FileOutlined, GithubOutlined } from "@ant-design/icons";

const HeaderPage = () => {
  const [, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const authors = [
    { name: "Andy Zhou", affiliation: "1", bioLink: "" },
    { name: "Kevin Wu", affiliation: "2", bioLink: "" },
    { name: "Yi Zeng", affiliation: "3,4", bioLink: "" },
    { name: "Yu Yang", affiliation: "3,5", bioLink: "" },
    { name: "Shuang Yang", affiliation: "5", bioLink: "" },
    { name: "Sanmi Koyejo", affiliation: "2,3", bioLink: "" },
    { name: "James Zou", affiliation: "2", bioLink: "" },
    { name: "Bo Li", affiliation: "1,3", bioLink: "" }
  ];

  const affiliations = [
    { name: "University of Illinois Urbana-Champaign", link: "" },
    { name: "Stanford University", link: "" },
    { name: "VirtueAI", link: "" },
    { name: "Virginia Tech", link: "" },
    { name: "University of California Los Angeles", link: "" },
    { name: "Meta AI", link: "" }
  ];
  let breakAuthorIndices = [3];

  const author_component = (
    <div className="mt-8">
      <p className="text-center text-[#3A6B65] text-sm sm:text-base md:text-lg font-semibold mb-4">
        {authors.map((author, index) => (
          <span key={index}>
            {author.bioLink ? (
              <a
                href={author.bioLink}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                {author.name}
              </a>
            ) : (
              <span>{author.name}</span>
            )}
            {author.contrib && <sup>{author.contrib}</sup>}
            <sup>{author.affiliation}</sup>
            {index !== authors.length - 1 && ", "}
            {breakAuthorIndices.includes(index) && <br />}
          </span>
        ))}
      </p>
      <p className="text-center text-xs sm:text-sm md:text-base mb-4 space-y-1">
        {affiliations.map((affiliation, index) => (
          <span key={index} className="block">
            <sup>{index + 1}</sup>
            {affiliation.link ? (<a
              href={affiliation.link}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              {affiliation.name}
            </a>): (
              <span> {affiliation.name}</span>
            )}
            {index !== affiliations.length - 1 && ", "}
          </span>
        ))}
      </p>
    </div>
  );

  const githubLink = "https://github.com/andyz245/AutoRedTeamer/";
  const paperLink = "https://arxiv.org/";

  return (
    <div className="mb-[31px] flex flex-col items-center">
      <h1 className="max-w-3xl mx-auto text-2xl sm:text-3xl md:text-4xl font-medium leading-tight text-center mt-20 mb-4.5">
        AutoRedTeamer
        <br /> An Autonomous Red Teaming Agent Against Language Models
      </h1>
      
      {author_component}

      <div className="flex flex-col items-center mt-5">
        <Space>
          <Button
            ghost
            className="customDashedButton flex items-center justify-center"
            href={paperLink}
            target="_blank"
          >
            <span className="text-center text-black text-lg font-medium leading-6">
              <FileOutlined className="mr-1" />
              Paper
            </span>
          </Button>
          <Button
            ghost
            className="customDashedButton flex items-center justify-center"
            href={githubLink}
            target="_blank"
          >
            <span className="text-center text-black text-lg font-medium leading-6">
              <GithubOutlined className="mr-1" />
              Github
            </span>
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default HeaderPage;
