import React, { useState, useEffect } from "react";
import { Divider } from "antd";

import "./BodyPage.scss";

const BodyPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dividerOrientation = isMobile ? "center" : "left";

  return (
    <div>
      <div>
        <div className="mb-10">
          {isMobile ? (
            <h2 className="text-xl font-semibold leading-9 mb-4.5 text-left mx-auto">
              <span
                className="pb-2 inline-block"
                style={{
                  borderBottom: `1px solid #93B3A5`,
                }}
              >
                AutoRedTeamer
              </span>
            </h2>
          ) : (
            <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
              <Divider orientation="left" className="custom-divider">
                <span>AutoRedTeamer</span>
              </Divider>
            </h2>
          )}

          <p className="text-base mt-8 font-normal leading-[30px] text-left mx-auto">
          As large language models (LLMs) become increasingly capable, robust and scalable security evaluation is crucial. While current red teaming approaches have made strides in assessing LLM vulnerabilities, they often rely heavily on human input and fail to provide comprehensive coverage of potential risks. This paper introduces AutoRedTeamer, a unified framework for fully automated, end-to-end red teaming against LLMs. AutoRedTeamer is an LLM-based agent architecture comprising five specialized modules and a novel memory-based attack selection mechanism, enabling deliberate exploration of new attack vectors. AutoRedTeamer supports both seed prompt and risk category inputs, demonstrating flexibility across red teaming scenarios. We demonstrate AutoRedTeamer’s superior performance in identifying potential vulnerabilities compared to existing manual and optimization-based approaches, achieving higher attack success rates by 20% on HarmBench against Llama-3.1-70B while reducing computational costs by 46%. Notably, AutoRedTeamer can break jailbreaking defenses and generate test cases with comparable diversity to human-curated benchmarks. AutoRedTeamer establishes the state of the art for automating the entire red teaming pipeline, a critical step towards comprehensive and scalable security evaluations of AI systems.          </p>
          <div className="mt-8 mb-8">
            <img
              src="images/png/pipeline.png"
              alt="AutoRedTeamer Illustration"
              className="max-w-[95%] h-auto block"
            />
            <p className="text-base italic text-center mt-4 max-w-[80%] mx-auto">
              Figure 1: An illustration of AutoRedTeamer. AutoRedTeamer is an LLM-agent-based dynamic red-teaming evaluation framework consisting of five modules: the Risk Analyzer, the Seed Prompt Generator, the Strategy Designer, the Evaluator, and the Relevancy Checker. The framework conducts red-teaming end to end with minimal human involvement besides the input domain.
            </p>
          </div>

          <div className="mt-8 mb-8">
            <img
              src="images/png/example.png"
              alt="Example Trajectory"
              className="max-w-[70%] h-auto block mx-auto"
            />
            <p className="text-base italic text-center mt-4 max-w-[70%] mx-auto">
              Figure 2: Example trajectory of a full round of refinement on a seed prompt. The final test case contains two attacks and is successful at eliciting unwanted behavior in the target LLM. The Strategy Designer reasons about optimal attack selections based on previously successful attacks, attack costs, and the specific test case being refined.
            </p>
          </div>

          <br />
        </div>
        <div className="mb-10">
          <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
            <Divider
              orientation={dividerOrientation}
              className="custom-divider"
            >
              Results on Seed Prompt Inputs
            </Divider>
          </h2>
          <p className="text-base font-normal leading-[30px] text-left mx-auto">
             Main results are shown below evaluation on HarmBench. AutoRedTeamer is more successful and efficient than baseline attacks.
          </p>

          <div className="mt-8 mb-8">
            <img
              src="images/png/table.png"
              alt="Harmbench"
              className="max-w-[80%] h-auto block mx-auto"
            />
            <p className="text-base italic text-center mt-4 max-w-[70%] mx-auto">
              Figure 3: Results on HarmBench against Llama-3.1-70B, GPT-4o, and Mixtral-8x7B. AutoRedTeamer achieves higher attack success rates and lower computational costs compared to existing manual and optimization-based approaches.
            </p>
          </div>
        
        </div>
        <div className="mb-10">
          <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
            <Divider
              orientation={dividerOrientation}
              className="custom-divider"
            >
              Results on Risk Category Inputs
            </Divider>
          </h2>
          <p className="text-base font-normal leading-[30px] text-left mx-auto">
             Main results are shown below evaluation on AIR risk categories. AutoRedTeamer can match the diversity of human-curated benchmark AIR-Bench while being more effective.
          </p>

          <div className="mt-8 mb-8">
            <img
              src="images/png/heatmap.png"
              alt="Harmbench"
              className="max-w-[100%] h-auto block mx-auto"
            />
            <p className="text-base italic text-center mt-4 max-w-[70%] mx-auto">
              Figure 4: Comparison of attack success rates on AIR risk categories on AIR-Bench static test cases (top) and AutoRedTeamer dynamic test cases (bottom). AutoRedTeamer achieves higher attack success rates than static evaluation.
            </p>
          </div>
        
        </div>
        <div className="mb-10">
          <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
            <Divider orientation="left" className="custom-divider">
              Citation
            </Divider>
          </h2>
          <div className="citation">
            <pre>
             <div>
              <div>{`@article{zhou2024autoredteamer,`}<br></br></div>
              <div style={{paddingLeft: '2em'}}>{`title={AutoRedTeamer: An Autonomous Red Teaming Agent Against Language Models},`}<br></br></div>
              <div style={{paddingLeft: '2em'}}>{`author={Andy Zhou and Kevin Wu and Yi Zeng and Yu Yang and Shuang Yang and Sanmi Koyejo and James Zou and Bo Li},`}<br></br></div>
              <div style={{paddingLeft: '2em'}}>{`year={2024},`}<br></br></div>
              <div style={{paddingLeft: '2em'}}>{`eprint={xxxx.xxxxx},`}<br></br></div>
              <div style={{paddingLeft: '2em'}}>{`archivePrefix={arXiv},`}<br></br></div>
              <div style={{paddingLeft: '2em'}}>{`primaryClass={cs.LG}`}</div>
              <div>{`}`}</div>
              </div>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyPage;
